<template>
  <b-card>
    <div class="blue box ex2">
      <div class="coral item">
        <div class="pink item">
          <!-- <h3>Push Message</h3> -->
          <h3>ข้อความจะถูกส่งไปที่ไลน์อัตโนมัติ เมื่อมีการเชื่อมต่ออินเทอร์เน็ตสำเร็จ</h3>
        <p style="color:red;">**โควต้าการส่งข้อความจะขึ้นอยู่กับไลน์แพ็กเกจที่ใช้</p>
        </div>
      </div>
      <div class="coral item">
        <div class="pink item">
          <b-button variant="primary" @click="$router.push('/PushMessage/add')">
            <span class="text-nowrap">สร้างใหม่</span>
          </b-button>
        </div>
      </div>
    </div>

    <hr />
    <b-row>
      <b-col xl="12" md="12" sm="12">
        <b-card
          no-body
          style="box-shadow: 5px 5px 10px #5e5454, -5px -5px 10px #ffffff"
        >
         
          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- <template #cell(preview)="data">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                pill
                @click="preview(data.item.items)"
              >
                แสดง
              </b-button>
            </template> -->
            <template #cell(name)="data">
              <span @click="edit_data(data.item.id)">
                {{ data.item.name }}
              </span>
            </template>
            <template #cell(branch)="data">
              <span @click="edit_data(data.item.id)">
                {{ data.item.branch }}
              </span>
            </template>
            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="edit_data(data.item.id)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Edit</span>
                </b-dropdown-item>

                <b-dropdown-item @click="delete_data(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- page length -->
            <b-form-group
              label="Per Page"
              label-cols="6"
              label-align="left"
              label-size="sm"
              label-for="sortBySelect"
              class="text-nowrap mb-md-0 mr-1"
            >
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                inline
                :options="pageOptions"
              />
            </b-form-group>

            <!-- pagination -->
            <div>
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BMedia,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BImg,
  BAlert,
} from "bootstrap-vue";
import api from "@/api";
import previwe_list from "./preview.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BMedia,
    BDropdown,
    BDropdownItem,
    BListGroup,
    BListGroupItem,
    BImg,
    BAlert,
    previwe_list,
  },
  data() {
    return {
      message_data: "",
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        { key: "name", label: "name" },
        { key: "branch", label: "สาขา", sortable: true },
        { key: "startDate", label: "ส่งแล้ว", sortable: true },
        { key: "endDate", label: "วันเวลา", sortable: true },
        // { key: "preview", label: "แสดง" },
        { key: "actions" },
      ],
      items: [],
      status: [
        {
          1: "online",
          2: "offline",
        },
        {
          1: "light-success",
          2: "light-danger",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.loaddata();
    // this.totalRows = this.items.length
  },
  methods: {
    preview(message_data) {
      this.$refs.datapreviwe.data_preview(message_data);
      // console.log('message_data :>> ', message_data);
    },
    delete_data(id) {
      console.log("delete", id);
      const params = {
        id: id,
      };
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          api
            .post("delete_wifimessage", params)
            .then((response) => {
              console.log(response.data);
              this.$swal({
                icon: "success",
                title: "Deleted!",
                text: "Your file has been deleted.",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              this.loaddata();
            })
            .catch((error) => {
              this.$swal({
                title: "Delete Error",
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            });
        } else if (result.dismiss === "cancel") {
          // this.$swal({
          //   title: "Cancelled",
          //   text: "Your imaginary file is safe :)",
          //   icon: "error",
          //   customClass: {
          //     confirmButton: "btn btn-success",
          //   },
          // });
        }
      });
    },
    edit_data(id) {
      console.log("edit_data", id);
      localStorage.setItem("message_id", id);
      this.$router.push({ path: "/PushMessage/edit/", params: { id: id } });
    },

    click(id) {
      alert(id);
    },
    loaddata() {
      const params = {
        // lineoa_id: 28,
        store_id: localStorage.getItem("store_id"),
      };
      api
        .post("getlist_wifimessage", params)
        .then((response) => {
          console.log(response.data);
          this.items = response.data.data;
          this.totalRows = response.data.total;
          console.log("items :>> ", this.items);
          // console.log("items [0]:>> ", this.items[0].items);
          this.message_data = this.items[0].items;
          this.preview(this.message_data);
        })
        .catch((error) => {
          // router.push('/pages/not-authorized');
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.ex2 {
  display: flex;
  justify-content: space-between;
}
</style>
